import { styled } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchemaIcon from "@mui/icons-material/Schema";

export const StyledSettingsIcon = styled(SettingsIcon)(() => ({
  height: "26px",
  width: "26px",
}));

export const StyledMenuBookIcon = styled(MenuBookIcon)(() => ({
  height: "22px",
  width: "22px",
}));

export const StyledSchemaIcon = styled(SchemaIcon)(() => ({
  height: "22px",
  width: "22px",
}));
