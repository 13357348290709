import { Box } from "@mui/material";
import React from "react";

import { AppContext } from "../App";
import { Governance } from "./Governance";
import { SideNavigationPage } from "../common/SideNavigationPage/SideNavigationPage";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetWorkspaceInfoDocument } from "../generated";
import { Typography } from "../common/Typography/Typography";
import { WorkspaceSidenav } from "../common/WorkspaceSidenav/WorkspaceSidenav";
import { GridView } from "@mui/icons-material";

export interface GovernancePageProps {}

export const GovernancePage = () => {
  const { workspaceId } = useParams();
  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const workspaceName = data?.workspace?.name || "";
  const isEditable = data?.workspace?.governance?.isEditable;

  return (
    <AppContext.Consumer>
      {({ showSidenav }) => (
        <SideNavigationPage
          leftHeaderElements={<Typography variant="h5">Policies</Typography>}
          content={
            <Box
              sx={{ mt: -4 }}
              display="flex"
              flexDirection="column"
              boxSizing="border-box"
              flexGrow={1}
            >
              <Governance />
            </Box>
          }
        />
      )}
    </AppContext.Consumer>
  );
};
