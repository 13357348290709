import { Box, Popover, useTheme } from "@mui/material";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { Button } from "../../common/Button/Button";
import { useEffect, useState } from "react";
import { AddSourceButton } from "../../common/ActionBarProjects/AddSourceButton";
import { TableViewIcon } from "../../common/Icons/TableViewIcon";
import { CardsViewIcon } from "../../common/Icons/CardsViewIcon";
import { ClassNames } from "@emotion/react";
import { DeleteTermDocument, SourceOutput } from "../../generated";
import { AddResourceButton } from "../../common/ActionBarProjects/AddResourceButton";
import { FilterButton } from "../../common/FilterButton/FilterButton";
import { useMutation } from "@apollo/client";
import { AddTermButton } from "./TermAdd/AddTermButton";
import { TermAdd } from "./TermAdd";
import { useLocation } from "react-router-dom";

export const ActionBar = ({
  data,
  resetRowData,
  setRowData,
}: {
  data: SourceOutput[];
  resetRowData: Function;
  setRowData: Function;
}) => {
  const { state }: any = useLocation();
  const [addTerm, setTerm] = useState("");
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    if (e.target.value !== "") {
      setRowData(
        data.filter((data: any) =>
          data.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      resetRowData();
    }
  };
  const { palette } = useTheme();

  useEffect(() => {
    if (state?.add) {
      setTerm("manually");
    }
  }, [state?.add]);

  return (
    <Box
      sx={{
        background: palette.common.white,
        border: `1px solid ${palette.action.disabledBackground}`,
      }}
    >
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={
          <SearchBar
            onChange={onChangeSearch}
            value={searchText}
            placeholder="Find a Glossary"
          />
        }
        rightElements={
          <>
            <AddTermButton onClick={setTerm} />
          </>
        }
      />

      {addTerm === "manually" && <TermAdd onClose={() => setTerm("")} />}
    </Box>
  );
};
