import { Box } from "@mui/material";
import { LeftRightContent } from "../../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../../common/SearchBar/SearchBar";
import { Button } from "../../../common/Button/Button";
import { useState } from "react";
import { TableViewIcon } from "../../../common/Icons/TableViewIcon";
import { CardsViewIcon } from "../../../common/Icons/CardsViewIcon";
import { SourceOutput } from "../../../generated";
import { LeftRightContentHolder } from "./style";
import AddSourcesSection from "../../../common/ActionBarProjects/AddSourcesSection";

export const ActionBar = ({
  data,
  resetRowData,
  setRowData,
  setCardView,
  setSource,
  setResource,
  cardView,
  loading,
}: {
  data: SourceOutput[];
  resetRowData: Function;
  setRowData: Function;
  setCardView: Function;
  setSource: (value: string | boolean) => void;
  setResource: (value: string | boolean) => void;
  cardView: any;
  loading: boolean;
}) => {
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    if (e.target.value !== "") {
      setRowData(
        data.filter((data: any) =>
          data.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      resetRowData();
    }
  };

  return (
    <LeftRightContentHolder>
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={
          <SearchBar
            onChange={onChangeSearch}
            value={searchText}
            placeholder="Find a source"
          />
        }
        rightElements={
          <Box className="rightelement" ml={2}>
            <Button
              onClick={() => setCardView(true)}
              className={`button-style ${cardView ? "active" : ""}`}
            >
              <CardsViewIcon />
            </Button>
            <Button
              onClick={() => setCardView(false)}
              className={`button-style ${cardView ? "" : "active"}`}
            >
              <TableViewIcon />
            </Button>
            <Box className="divider"></Box>
            <AddSourcesSection
              loading={loading}
              setSource={setSource}
              setResource={setResource}
            />
          </Box>
        }
      />
      {/* <AddEngineDialog open={open} onClose={() => setOpen(false)} /> */}
    </LeftRightContentHolder>
  );
};
