import { ButtonPrimary } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const ConfigureAirbyteMsg = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <Typography color="GrayText" variant="subtitle3">
        Airbyte is not configured
      </Typography>

      <ButtonPrimary
        onClick={() =>
          navigate(`/workspace/${workspaceId}/configure/integration`)
        }
      >
        Configure Airbyte
      </ButtonPrimary>
    </>
  );
};

export default ConfigureAirbyteMsg;
