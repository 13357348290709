import { useEffect, useState } from "react";
import { ActionBar } from "./ActionBar";
import { BasicCard } from "../../../common/BasicCard/BasicCard";
import { Box, Typography } from "@mui/material";
import { GridView } from "./GridView";
import { CardView } from "./CardView";
import { AddDetail } from "../AddDetail/AddDetail";
import { SourceOutput } from "../../../generated";
import { SimpleLoader } from "../../../common/Loader/Loader";
import { size } from "lodash";
import AddSourcesModals from "../../../common/ActionBarProjects/AddSourcesModals";

// const data = [
//   {
//     name: "PortFort",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     url: "https://www.google.com/",
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "BrightHive",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "RotMOt",
//     status: "Requested",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
// ];

export const AllGrid = ({ data, loading }: { data: any; loading: boolean }) => {
  const [source, setSource] = useState<string | boolean>(false);
  const [resource, setResource] = useState<string | boolean>(false);
  const [cardView, setCardView] = useState(true);
  const [rowData, setRowData] = useState<any>(data);
  const [detail, setDetail] = useState<SourceOutput>();

  useEffect(() => {
    setRowData(data);
  }, [data]);

  return (
    <Box>
      <BasicCard
        sx={{
          background: "transparent",
          boxShadow: "none",
          border: "none",
        }}
      >
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
          setCardView={setCardView}
          setSource={setSource}
          setResource={setResource}
          cardView={cardView}
          loading={loading}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "8rem 0" }}
            >
              <SimpleLoader />
            </Box>
          ) : size(rowData) >= 1 ? (
            cardView ? (
              <CardView rowData={rowData} setDetail={setDetail} />
            ) : (
              <GridView rowData={rowData} setDetail={setDetail} />
            )
          ) : (
            <Typography mt={8} textAlign={"center"}>
              No Record Found
            </Typography>
          )}
        </Box>
      </BasicCard>
      <AddDetail data={detail} onClose={() => setDetail(undefined)} />

      <AddSourcesModals
        source={source}
        resource={resource}
        setSource={setSource}
        setResource={setResource}
      />
    </Box>
  );
};
