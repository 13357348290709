import { Box, Popover, useTheme } from "@mui/material";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { Button } from "../../common/Button/Button";
import { useState } from "react";
import { AddSourceButton } from "../../common/ActionBarProjects/AddSourceButton";
import { TableViewIcon } from "../../common/Icons/TableViewIcon";
import { CardsViewIcon } from "../../common/Icons/CardsViewIcon";
import { ClassNames } from "@emotion/react";
import { SourceOutput } from "../../generated";
import { AddResourceButton } from "../../common/ActionBarProjects/AddResourceButton";
import { ButtonDefault } from "./style";
import AddSourcesSection from "../../common/ActionBarProjects/AddSourcesSection";

export const ActionBar = ({
  data,
  resetRowData,
  setRowData,
  setCardView,
  setResource,
  setSource,
  cardView,
  loading,
}: {
  data: SourceOutput[];
  resetRowData: Function;
  setRowData: Function;
  setCardView: Function;
  setResource: (value: string | boolean) => void;
  setSource: (value: string | boolean) => void;
  cardView: any;
  loading: boolean;
}) => {
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    if (e.target.value !== "") {
      setRowData(
        data.filter((data: any) =>
          data.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      resetRowData();
    }
  };
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        background: palette.common.white,
        border: `1px solid ${palette.action.disabledBackground}`,
        borderRadius: "6px",
      }}
    >
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={
          <SearchBar
            onChange={onChangeSearch}
            value={searchText}
            placeholder="Find documents, files, images..."
          />
        }
        rightElements={
          <Box
            display="flex"
            alignItems="center"
            ml={2}
            sx={{
              gap: "12px",
            }}
          >
            <ButtonDefault
              onClick={() => setCardView(true)}
              className={`${cardView ? "active small" : "small"}`}
            >
              <CardsViewIcon />
            </ButtonDefault>
            <ButtonDefault
              onClick={() => setCardView(false)}
              className={`${cardView ? "small" : "active small"}`}
            >
              <TableViewIcon />
            </ButtonDefault>
            <Box
              sx={{
                borderLeft: `1px solid ${palette.action.disabledBackground}`,
                width: "1px",
                height: "36px",
              }}
            ></Box>
            <AddSourcesSection
              loading={loading}
              setSource={setSource}
              setResource={setResource}
            />
          </Box>
        }
      />
      {/* <AddEngineDialog open={open} onClose={() => setOpen(false)} /> */}
    </Box>
  );
};
