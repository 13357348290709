import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";

import { useParams } from "react-router-dom";
import { SimpleLoader } from "../../Loader/Loader";
import { Specification } from "../../../Sources/All/AddSource/Internal/Specification";
import {
  CreateIngestionServiceDocument,
  GetCurrentUserInfoDocument,
  GetIngestionServicesDocument,
  IngestionServiceProvider,
} from "../../../generated";
import { Button } from "../../Button/Button";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import { CustomTextField } from "../../CustomTextField/CustomTextField";
import { toast } from "react-toastify";
import { Search } from "../Transformation/Search";
import { Fields } from "./Fields";
import RightSidePanel from "../../RightSidePanel";
import {
  ButtonPrimary,
  PanelHolder,
  PanelContent,
  PanelTopContent,
  PanelContentHead,
  HeadSmallTitle,
  HeadTitle,
  SubHeading,
  RadioHolder,
  LabelTitle,
  LableDes,
  FormCenter,
  FooterBtnHolder,
} from "../style";

const options = [
  {
    title: IngestionServiceProvider.AirbyteOss.replaceAll("_", " "),
    value: IngestionServiceProvider.AirbyteOss,
    method: "On Platform",
    owner: "Brighthive",
    description: "Description goes here",
  },
];

export const AddIntegrations = () => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  const [create, { loading }] = useMutation(CreateIngestionServiceDocument, {
    refetchQueries: [GetIngestionServicesDocument],
  });

  const { data } = useQuery(GetCurrentUserInfoDocument);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained">
        Add Integration
      </Button>
      {open && (
        <RightSidePanel isOpen={open} onToggle={close}>
          <PanelHolder>
            <Formik
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required"),
                description: Yup.string(),
                provider: Yup.string().required("Provider Name is required"),
                configurations: Yup.object().shape({
                  username: Yup.string().required("Username is required"),
                  password: Yup.string().required("Password is required"),
                  api_url: Yup.string()
                    .url("Must be a valid URL")
                    .required("API URL is required"),
                  ui_url: Yup.string()
                    .url("Must be a valid URL")
                    .required("UI URL is required"),
                  workspace_name: Yup.string().required(
                    "Workspace Name is required"
                  ),
                  awsAccessKeyID: Yup.string(),
                  awsSecretAccessKey: Yup.string(),
                  awsAccountNumber: Yup.string(),
                }),
              })}
              initialValues={{
                name: "",
                description: "",
                transformationMethod: "managed-services",
                provider: null as any,
                configurations: {
                  username: "",
                  password: "",
                  api_url: "",
                  ui_url: "",
                  workspace_name: "",
                  awsAccessKeyID: "",
                  awsSecretAccessKey: "",
                  awsAccountNumber: "",
                  webhook_url: "",
                },
              }}
              onSubmit={(values) => {
                const { transformationMethod, configurations, ...restValues } =
                  values;

                create({
                  variables: {
                    input: {
                      workspaceId: workspaceId || "",
                      managerId: data?.currentUser.id || "",
                      configurations: JSON.stringify(configurations),
                      ...restValues,
                    },
                  },
                }).then((res) => {
                  toast.success(
                    "Service created " +
                      res.data?.createIngestionService.ingestionServiceId
                  );
                  close();
                });
              }}
              validateOnBlur={true}
              validateOnChange={true}
            >
              {({
                handleChange,
                errors,
                touched,
                handleSubmit,
                values,
                setFieldValue,
                handleBlur,
                isValid,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <PanelContent>
                    <PanelTopContent>
                      <PanelContentHead>
                        <HeadSmallTitle>Integration</HeadSmallTitle>
                        <HeadTitle>New Integration</HeadTitle>
                      </PanelContentHead>
                      {/*  <SubHeading>Connection Service Type</SubHeading>
                      <RadioHolder>
                        <FormControl>
                          <RadioGroup
                            defaultValue="platform"
                            name="transformationMethod"
                            value={values.transformationMethod}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="managed-services"
                              control={<Radio />}
                              className="formlist"
                              label={
                                <>
                                  <LabelTitle>Managed Service</LabelTitle>
                                  <LableDes>
                                    Securely transform data using engines
                                    private to this workspace. This means your
                                    data never leaves the workspace.
                                  </LableDes>
                                </>
                              }
                            />

                            <FormControlLabel
                            value={"Customer-hosted"}
                            control={<Radio />}
                            label={
                              <>
                                <Typography
                                  variant="body1"
                                  mb={2}
                                  fontSize="1rem"
                                  lineHeight="1"
                                  fontWeight={400}
                                  className="label-title"
                                  color={palette.common.black}
                                >
                                  Customer-hosted
                                </Typography>
                                <Typography
                                  variant="body1"
                                  fontSize="0.875rem"
                                  lineHeight="1.2"
                                  fontWeight={400}
                                  color={palette.gray.darkest}
                                >
                                  Transform data off-platform using external
                                  engines managed by an approved workspace
                                  organization.
                                </Typography>
                              </>
                            }
                          />
                          <FormControlLabel
                            value={"custom-link"}
                            control={<Radio />}
                            label={
                              <>
                                <Typography
                                  variant="body1"
                                  mb={2}
                                  fontSize="1rem"
                                  lineHeight="1"
                                  fontWeight={400}
                                  className="label-title"
                                  color={palette.common.black}
                                >
                                  Custom link
                                </Typography>
                                <Typography
                                  variant="body1"
                                  fontSize="0.875rem"
                                  lineHeight="1.2"
                                  fontWeight={400}
                                  color={palette.gray.darkest}
                                >
                                  Link to the service for reference, but
                                  brightbot can’t interact with it
                                </Typography>
                              </>
                            }
                          />
                          </RadioGroup>
                        </FormControl>
                      </RadioHolder> */}
                      <FormCenter>
                        {true && (
                          <>
                            <Search
                              options={options}
                              setFieldValue={setFieldValue}
                              values={values}
                              resetForm={resetForm}
                            />

                            {values.provider && (
                              <Fields
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                touched={touched}
                              />
                            )}
                          </>
                        )}
                      </FormCenter>
                    </PanelTopContent>
                    <FooterBtnHolder>
                      <Button onClick={() => setOpen(false)} variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading || !isValid || !values.provider}
                        type="submit"
                      >
                        Add Service
                      </Button>
                    </FooterBtnHolder>
                  </PanelContent>
                </form>
              )}
            </Formik>
          </PanelHolder>
        </RightSidePanel>
      )}
    </>
  );
};
