import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetIngestionServicesDocument } from "../../generated";
import { CircularProgress, useTheme } from "@mui/material";
import { AddSourcesButton } from "./AddSourcesButton";
import ConfigureAirbyteMsg from "./ConfigureAirbyteMsg";

type Props = {
  loading: boolean;
  setSource: (value: string | boolean) => void;
  setResource: (value: string | boolean) => void;
};

const AddSourcesSection = ({ loading, setSource, setResource }: Props) => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data: ingestionData, loading: ingestionLoading } = useQuery(
    GetIngestionServicesDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
      },
    }
  );

  const isAirbyteConfigured =
    ingestionData?.workspace?.services?.ingestionServices &&
    ingestionData?.workspace?.services?.ingestionServices?.length > 0;

  return (
    <>
      {!loading && !ingestionLoading ? (
        <>
          {!isAirbyteConfigured && <ConfigureAirbyteMsg />}
          <AddSourcesButton
            isAirbyteConfigured={isAirbyteConfigured}
            setSource={setSource}
            setResource={setResource}
          />
        </>
      ) : (
        <CircularProgress size={24} sx={{ color: palette.primary.darkest }} />
      )}
    </>
  );
};

export default AddSourcesSection;
