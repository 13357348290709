import * as Yup from "yup";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  MenuItem,
  useTheme,
} from "@mui/material";
import { Formik, Field } from "formik";
import { useMutation } from "@apollo/client";
import { Select } from "../../../../common/Form/Select";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomTextArea } from "../../../../common/CustomTextArea/CustomTextArea";
import { CloseIcon } from "../../../../common/Icons/CloseIcon";
import { convertToTitleCase } from "../../../../helpers";
import {
  CreateTermDocument,
  ScopeType,
  TermsDocument,
} from "../../../../generated";
import { Typography } from "../../../../common/Typography/Typography";
import { MAX_DESCRIPTION_CHARS } from "../../../../Constants";
import { CustomTextField } from "../../../../common/CustomTextField/CustomTextField";

export const CreateTerm = ({ onClose }: any) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  const [createTerm, { loading }] = useMutation(CreateTermDocument, {
    refetchQueries: [TermsDocument],
  });

  return (
    <Formik
      validateOnBlur
      initialValues={
        {
          scope: ScopeType.Workspace,
        } as any
      }
      initialErrors={
        {
          scope: ScopeType.Workspace,
        } as any
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().max(MAX_DESCRIPTION_CHARS),
        type: Yup.string().required(),
        scope: Yup.string().oneOf(Object.values(ScopeType)),
      })}
      onSubmit={async (values, { validateForm }) => {
        if (Object.keys(validateForm()).length > 0) {
          return;
        }
        createTerm({
          variables: {
            input: {
              workspaceId: workspaceId || "",
              type: values.type,
              scope: values.scope,
              name: values.name,
              description: values.description,
            },
          },
        })
          .then((res) => {
            toast.success("Term Added");
            onClose();
          })
          .catch((err) => {
            toast.error("Error");
          });
      }}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <Box width={400} p={6}>
          <Box
            boxSizing="border-box"
            sx={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  paddingRight: "20px",
                  paddingBottom: "16px",
                  borderBottom: `1px solid ${palette.outline}`,
                }}
                mb={6}
              >
                <Typography
                  mb={2}
                  variant="h5"
                  color={palette.gray.dark}
                  sx={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  Glossary
                </Typography>
                <Typography
                  mb={4}
                  variant="h5"
                  color={palette.common.black}
                  sx={{ wordBreak: "break-word" }}
                >
                  New Glossary
                </Typography>
              </Box>
            </Box>

            <Box boxSizing="border-box">
              <Box mb={6}>
                <Box
                  sx={{
                    paddingRight: "20px",
                    paddingBottom: "16px",
                    borderBottom: `1px solid ${palette.outline}`,
                  }}
                  mb={6}
                >
                  <Box mb={6} display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      mb={2}
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={palette.common.black}
                    >
                      Glossary Name
                    </Typography>

                    <Field
                      component={CustomTextField}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={errors.name}
                      touched={touched.name}
                    />
                  </Box>
                  <Box mb={6} display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      mb={2}
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={palette.common.black}
                    >
                      Glossary Description
                    </Typography>

                    <Field
                      component={CustomTextArea}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      error={errors.description}
                      touched={touched.description}
                    />
                  </Box>
                  <Box mb={6} display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      mb={2}
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={palette.common.black}
                    >
                      Glossary Type
                    </Typography>

                    <Field
                      component={CustomTextField}
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      error={errors.type}
                      touched={touched.type}
                    />
                  </Box>
                  <Box mb={6} display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      mb={2}
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={palette.common.black}
                    >
                      Glossary Scope
                    </Typography>
                    <Box>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="scope"
                          placeholder="Select target schema validation method"
                          label="scope"
                          onChange={handleChange}
                          value={values.scope}
                          sx={{
                            fieldset: {
                              top: "0",
                            },
                            legend: {
                              display: "none",
                            },
                            ".MuiInputBase-input": {
                              width: "100%",
                              height: "36px",
                              fontSize: "16px",
                              lineHeight: "20px",
                              padding: "8px",
                              boxSizing: "border-box",
                            },
                          }}
                        >
                          {Object.entries(ScopeType).map((val, i) => (
                            <MenuItem value={val[1]}>
                              {convertToTitleCase(val[1])}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {console.log(errors)}
            <Box>
              <Button
                color="primary"
                variant="contained"
                sx={{ width: "100%", marginBottom: "10px" }}
                disabled={loading || Object.keys(errors).length > 0}
                onClick={(e) => handleSubmit()}
              >
                Save
              </Button>
              <Button
                color="grayAccent"
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};
