import { Box, Drawer, IconButton, Modal } from "@mui/material";
import { AddSource } from "../../Sources/All/AddSource/AddSource";
import { Close } from "@mui/icons-material";
import { CreateFolder } from "../../KnowledgeCenter/AddResource/CreateFolder";
import { AddResourceTab } from "../../KnowledgeCenter/AddResource";
import { StyledAddResourceModal } from "./style";

type Props = {
  source: string | boolean;
  resource: string | boolean;
  setSource: (value: string | boolean) => void;
  setResource: (value: string | boolean) => void;
};

const AddSourcesModals = ({
  source,
  resource,
  setSource,
  setResource,
}: Props) => {
  const closeDialog = () => {
    setSource(false);
    setResource(false);
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={(source && true) || false}
        onClose={closeDialog}
      >
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => closeDialog()} size="small">
                <Close />
              </IconButton>
            </Box>
            <AddSource source={source} close={closeDialog} />
          </Box>
        </Box>
      </Drawer>

      {resource === "Folder" ? (
        <Modal open={resource as any} onClose={() => setResource("")}>
          <CreateFolder onClose={() => setResource("")} />
        </Modal>
      ) : (
        <StyledAddResourceModal
          open={resource as any}
          onClose={() => setResource("")}
        >
          <AddResourceTab setResource={setResource} resource={resource} />
        </StyledAddResourceModal>
      )}
    </>
  );
};

export default AddSourcesModals;
