import { Divider, Typography, useTheme } from "@mui/material";
import { Internal } from "./Internal";
import { Marketplace } from "./Marketplace";
import { Partner } from "./Partner";
import { Subscription } from "./Subscription";

export const AddSource = ({
  source,
  close,
}: {
  source: string | boolean;
  close: Function;
}) => {
  const { palette } = useTheme();
  return (
    <>
      <Typography
        sx={{
          color: palette.common.black,
          letterSpacing: "0.0025em",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "28px",
          paddingBottom: "17px",
          marginBottom: "24px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0 100%",
          backgroundSize: "calc(100% - 104px) 1px",
        }}
      >
        {`Add Data Source`}
        <Divider sx={{ marginTop: "1.2rem" }} />
      </Typography>

      <Internal close={close} />

      {source === "Partner" && <Partner close={close} />}

      {source === "Subscription" && <Subscription close={close} />}

      {source === "Marketplace" && <Marketplace close={close} />}
    </>
  );
};
