import { useState } from "react";
import { Button } from "../../Button/Button";
import { Box, Typography } from "@mui/material";
import { CustomTextField } from "../../CustomTextField/CustomTextField";
import { CustomTextArea } from "../../CustomTextArea/CustomTextArea";
import { OpenInBrowser } from "@mui/icons-material";
import { CustomField } from "./CustomField";
import {
  SubHeading,
  HeadTitle,
  FieldsHolder,
  FieldsSet,
  Label,
} from "../style";
export const Fields = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}: any) => {
  return (
    <>
      <FieldsHolder>
        <HeadTitle>About</HeadTitle>
        <Box display="flex" flexDirection="column">
          <FieldsSet>
            <Label htmlFor="connectionName">
              Integration Service Name <span className="asterisk">*</span>
            </Label>
            <CustomTextField
              id="connectionName"
              name="name"
              onBlur={handleBlur}
              value={values?.name}
              onChange={handleChange}
              error={errors?.name}
              touched={touched?.name}
            />
          </FieldsSet>
          <FieldsSet>
            <Label htmlFor="description">Integration Description</Label>
            <CustomTextArea
              id="description"
              name="description"
              onBlur={handleBlur}
              value={values?.description}
              onChange={handleChange}
              error={errors?.description}
              touched={touched?.description}
            />
          </FieldsSet>
          <HeadTitle className="mt">Configurations</HeadTitle>
          <FieldsSet>
            <CustomField
              id="username"
              name={"configurations.username"}
              onBlur={handleBlur}
              value={values?.configurations?.username}
              handleChange={handleChange}
              error={errors?.configurations?.username}
              touched={touched?.configurations?.username}
            >
              <Label htmlFor="username">
                Username <span className="asterisk">*</span>
              </Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="password"
              name={"configurations.password"}
              onBlur={handleBlur}
              value={values?.configurations?.password}
              handleChange={handleChange}
              error={errors?.configurations?.password}
              touched={touched?.configurations?.password}
              type="password"
            >
              <Label htmlFor="password">
                Password <span className="asterisk">*</span>
              </Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="api_url"
              name={"configurations.api_url"}
              onBlur={handleBlur}
              value={values?.configurations?.api_url}
              handleChange={handleChange}
              error={errors?.configurations?.api_url}
              touched={touched?.configurations?.api_url}
            >
              <Label htmlFor="api_url">
                Api Url <span className="asterisk">*</span>
              </Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="ui_url"
              name={"configurations.ui_url"}
              onBlur={handleBlur}
              value={values?.configurations?.ui_url}
              handleChange={handleChange}
              error={errors?.configurations?.ui_url}
              touched={touched?.configurations?.ui_url}
            >
              <Label htmlFor="ui_url">
                Ui Url <span className="asterisk">*</span>
              </Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="workspace_name"
              name={"configurations.workspace_name"}
              onBlur={handleBlur}
              value={values?.configurations?.workspace_name}
              handleChange={handleChange}
              error={errors?.configurations?.workspace_name}
              touched={touched?.configurations?.workspace_name}
            >
              <Label htmlFor="workspace_name">
                Workspace Name <span className="asterisk">*</span>
              </Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="awsAccessKeyID"
              name={"configurations.awsAccessKeyID"}
              onBlur={handleBlur}
              value={values?.configurations?.awsAccessKeyID}
              handleChange={handleChange}
              error={errors?.configurations?.awsAccessKeyID}
              touched={touched?.configurations?.awsAccessKeyID}
            >
              <Label htmlFor="awsAccessKeyID">Aws Access Key ID</Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="awsSecretAccessKey"
              name={"configurations.awsSecretAccessKey"}
              onBlur={handleBlur}
              value={values?.configurations?.awsSecretAccessKey}
              handleChange={handleChange}
              error={errors?.configurations?.awsSecretAccessKey}
              touched={touched?.configurations?.awsSecretAccessKey}
            >
              <Label htmlFor="awsSecretAccessKey">Aws Secret Access Key</Label>
            </CustomField>
          </FieldsSet>
          <FieldsSet>
            <CustomField
              id="connectionAwsAccount"
              name={"configurations.awsAccountNumber"}
              onBlur={handleBlur}
              value={values?.configurations?.awsAccountNumber}
              handleChange={handleChange}
              error={errors?.configurations?.awsAccountNumber}
              touched={touched?.configurations?.awsAccountNumber}
            >
              <Label htmlFor="connectionAwsAccount">Aws Account Number</Label>
            </CustomField>
          </FieldsSet>
          {/* <FieldsSet>
            <CustomField
              name={"configurations.webhook_url"}
              onBlur={handleBlur}
              value={values?.configurations?.webhook_url}
              handleChange={handleChange}
              error={errors?.configurations?.webhook_url}
              touched={touched?.configurations?.webhook_url}
            >
              <SubHeading className="add">webhook_url</SubHeading>
            </CustomField>
          </FieldsSet> */}
        </Box>
      </FieldsHolder>
    </>
  );
};
