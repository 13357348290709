import { useEffect, useMemo, useState } from "react";
import { ActionBar } from "./ActionBar";
import { Box } from "@mui/material";
import { GridView } from "./GridView";
import { CardView } from "./CardView";
import { SimpleLoader } from "../../common/Loader/Loader";
import { size } from "lodash";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";
import { FolderIcon } from "../../common/Icons/FolderIcon";
import { useLocation } from "react-router-dom";
import ViewDrawer from "../Resources/Drawers/ViewDrawer";
import {
  DisplayDrawerHandlerType,
  DrawerDisplayerType,
} from "../../common/Drawer";
import {
  StyledBasicCard,
  StyledFolderContainer,
  StyledHelperContainer,
  StyledHelperRecordsTypography,
  StyledHelperTypography,
  StyledIconFolderContainer,
  StyledLoaderBox,
} from "./style";
import AddSourcesModals from "../../common/ActionBarProjects/AddSourcesModals";

// const data = [
//   {
//     name: "PortFort",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     url: "https://www.google.com/",
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "BrightHive",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "RotMOt",
//     status: "Requested",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
// ];

type Props = {
  data: any;
  loading: boolean;
  isFolder?: any;
  cardView?: boolean;
  setCardView: Function;
};

export const AllGrid = ({
  data,
  loading,
  isFolder,
  cardView,
  setCardView,
}: Props) => {
  const { state }: any = useLocation();
  const [resource, setResource] = useState<string | boolean>("");
  const [source, setSource] = useState<string | boolean>("");
  const [rowData, setRowData] = useState<any>(data);
  const [drawerDisplayer, setDrawerDisplayer] = useState<DrawerDisplayerType>({
    type: null,
    id: undefined,
  });

  const displayDrawerHandler: DisplayDrawerHandlerType = (type, id?) => {
    setDrawerDisplayer({ type, id });
  };

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  const fullName = `${userInfo?.currentUser?.firstName || ""} ${
    userInfo?.currentUser?.lastName
  }`;
  const emailAddress = userInfo?.currentUser?.emailAddress;

  useEffect(() => {
    setRowData(data);
  }, [data]);

  useEffect(() => {
    if (state?.add) {
      setResource("File");
    }
  }, [state?.add]);

  const selectedRow = useMemo(() => {
    if (!drawerDisplayer.id || rowData.length === 0) return null;

    return rowData.find((row: any) => row.id === drawerDisplayer.id);
  }, [drawerDisplayer.id]);

  return (
    <Box>
      <StyledBasicCard>
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
          setCardView={setCardView}
          setResource={setResource}
          setSource={setSource}
          cardView={cardView}
          loading={loading}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <StyledLoaderBox
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SimpleLoader />
            </StyledLoaderBox>
          ) : size(rowData) >= 1 ? (
            cardView ? (
              <>
                <StyledHelperContainer>
                  <StyledHelperTypography variant="caption">
                    Displaying {size(rowData)} resources
                  </StyledHelperTypography>
                </StyledHelperContainer>
                <CardView
                  rowData={rowData}
                  username={fullName}
                  emailAddress={emailAddress}
                  displayDrawerHandler={displayDrawerHandler}
                />
              </>
            ) : (
              <GridView
                rowData={rowData}
                username={fullName}
                emailAddress={emailAddress}
                displayDrawerHandler={displayDrawerHandler}
              />
            )
          ) : isFolder ? (
            <StyledFolderContainer>
              <StyledIconFolderContainer>
                <FolderIcon />
              </StyledIconFolderContainer>
              <StyledHelperRecordsTypography mt={2}>
                No Record Found
              </StyledHelperRecordsTypography>
            </StyledFolderContainer>
          ) : (
            <StyledHelperRecordsTypography mt={8}>
              No Record Found
            </StyledHelperRecordsTypography>
          )}
        </Box>
      </StyledBasicCard>
      {/* <AddDetail data={detail} onClose={() => setDetail(undefined)} /> */}

      {/* <Drawer
        anchor="right"
        open={(resource && true) || false}
        onClose={closeDialog}
      >
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => closeDialog()} size="small">
                <Close />
              </IconButton>
            </Box>
            <AddSource
              source={source}
              setSource={setSource}
              close={closeDialog}
            />
          </Box>
        </Box>

      </Drawer> */}

      <AddSourcesModals
        source={source}
        resource={resource}
        setSource={setSource}
        setResource={setResource}
      />

      <ViewDrawer
        drawerDisplayer={drawerDisplayer}
        displayDrawerHandler={displayDrawerHandler}
        data={selectedRow}
      />
    </Box>
  );
};
