import { Box, Typography } from "@mui/material";
import { AppContext } from "../../App";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { useQuery } from "@apollo/client";
import {
  GetProjectDocument,
  GetSourcesDocument,
  SourceType,
} from "../../generated";

import { useLocation, useParams } from "react-router-dom";
import { AllGrid } from "./AllGrid/AllGrid";

export const All = () => {
  const { projectId, workspaceId } = useParams();
  const location = useLocation();

  const active = location.pathname
    ?.split(`/workspace/${workspaceId}/`)[1]
    ?.split("/")[0];

  const source =
    active === "sources"
      ? location.pathname
          ?.split(`/workspace/${workspaceId}/sources/`)[1]
          ?.split("/")[0]
      : null;

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const { data: sources, loading } = useQuery(GetSourcesDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      sourceFilter: {
        sourceType: SourceType.Internal,
      },
    },
  });

  const workspace = data?.workspace;
  const project = data?.workspace?.projects?.[0];

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            className="sources-view"
            innerSideBar
            leftHeaderElements={
              <Box
                sx={{
                  textTransform: "capitalize",
                }}
              >
                <Typography variant="h5">
                  {!source ? "Data" : source} Sources
                </Typography>
              </Box>
            }
            // verticalNav={
            //   <SourcesNav
            //     show={showSidenav}
            //     workspaceName={workspace?.name || ""}
            //     setShowSidenav={setShowSidenav}
            //   />
            // }
            content={
              <AllGrid
                data={sources?.workspace?.services?.sources || []}
                loading={loading}
              />
            }
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
