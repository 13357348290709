import { Collapse, List, Popover, useTheme } from "@mui/material";
import { useMenu } from "../Menu/useMenu";
import {
  ButtonPrimary,
  StyledAddSourcesBtn,
  StyledAddSourcesSubBtn,
  StyledPopover,
} from "./style";
import { convertToTitleCase } from "../../helpers";
import { Button } from "../Button/Button";
import { PlusIcon } from "../Icons/PlusIcon";
import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

type Props = {
  isAirbyteConfigured: boolean | undefined;
  setSource: (value: string | boolean) => void;
  setResource: (value: string | boolean) => void;
};

type MenuStateType = {
  label: string;
  value: string;
  isDisabled: boolean | undefined;
  handler?: Function;
  open?: boolean;
  submenu?: MenuStateType[];
};

type MenuStateFunction = (
  isAirbyteConfigured: boolean | undefined,
  setSource: Function,
  setResources: Function
) => MenuStateType[];

const intialMenuState: MenuStateFunction = (
  isAirbyteConfigured,
  setSource,
  setResource
) => [
  {
    label: "Data Source",
    value: "data-source",
    isDisabled: !isAirbyteConfigured,
    handler: setSource,
  },
  {
    label: "Resource",
    value: "resource",
    isDisabled: false,
    open: false,
    handler: setResource,
    submenu: [
      {
        label: "Create Folder",
        value: "Folder",
        isDisabled: false,
      },
      { label: "File/Doc", value: "File", isDisabled: false },
      { label: "Image", value: "IMAGE", isDisabled: false },
      { label: "Video", value: "VIDEO", isDisabled: false },
      { label: "Link", value: "LINK", isDisabled: false },
    ],
  },
];

export const AddSourcesButton = ({
  isAirbyteConfigured,
  setSource,
  setResource,
}: Props) => {
  const [menuState, setMenuState] = useState(
    intialMenuState(isAirbyteConfigured, setSource, setResource)
  );
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { palette } = useTheme();

  const handleOpenSubMenu = (value: string) => {
    setMenuState((prev) =>
      prev.map((item) => {
        if (item.value !== value) return item;

        return { ...item, open: !item.open };
      })
    );
  };

  const closeMenuHandler = () => {
    closeMenu();
    setMenuState(intialMenuState(isAirbyteConfigured, setSource, setResource));
  };

  return (
    <>
      <ButtonPrimary
        id="project-action-button"
        aria-controls={menuOpen ? "project-action-items" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
      >
        <PlusIcon sx={{ fontSize: "16px", marginRight: "5px" }} /> Add
      </ButtonPrimary>

      <StyledPopover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={closeMenuHandler}
      >
        <>
          <List component="div">
            {menuState.map(
              ({ label, value, isDisabled, submenu, open, handler }) => (
                <>
                  <StyledAddSourcesBtn
                    key={`${value}-menu`}
                    fullWidth
                    id="project-action-button"
                    aria-controls={
                      menuOpen ? "project-action-items" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    color="grayAccent"
                    variant="outlined"
                    disabled={isDisabled}
                    onClick={() => {
                      if (submenu) {
                        handleOpenSubMenu(value);
                        return;
                      }
                      if (handler) handler(value);
                      closeMenuHandler();
                    }}
                  >
                    {convertToTitleCase(label)}
                    {submenu && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
                  </StyledAddSourcesBtn>
                  {submenu && (
                    <>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {submenu.map(
                            ({
                              label: subLabel,
                              value: subValue,
                              isDisabled: isSubDisabled,
                            }) => (
                              <>
                                <StyledAddSourcesSubBtn
                                  key={`${subLabel}-sub-menu`}
                                  fullWidth
                                  id="project-action-button"
                                  aria-controls={
                                    menuOpen
                                      ? "project-action-items"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={menuOpen ? "true" : undefined}
                                  color="grayAccent"
                                  variant="outlined"
                                  disabled={isSubDisabled}
                                  onClick={() => {
                                    closeMenuHandler();
                                    if (handler) handler(subValue);
                                  }}
                                >
                                  {convertToTitleCase(subLabel)}
                                </StyledAddSourcesSubBtn>
                              </>
                            )
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                </>
              )
            )}
          </List>
        </>
      </StyledPopover>
    </>
  );
};
